import React from "react";
import { graphql } from "gatsby";
import LayoutArraiolos from "../components/LayoutArraiolos";
import PageTitles from "../components/helpers/pageTitles";
import { SEO } from "../components/Seo";

const StudioPage = ({ data, location }) => {
  const { markdownRemark: post } = data;
  const content = post.frontmatter;

  return (
    <LayoutArraiolos pageTitle={PageTitles.STUDIO} location={location}>
      <div className="column is-vcentered garam fade-in">
        <section className="studio layout-margin">
          <div>
            <h1>{content.studioName}</h1>
            {content.descriptionParagraphs.map((entry, index) => { return (<p key={index}>{entry.paragraph}</p>); })}
          </div>
          <div>
            {content.architects.map((architect, index) => {
              return (
                <>
                  <h1 key={"architect" + index}>{architect.name}</h1>
                  {architect.achievements.map((entry, index) => {
                    return (<p key={"achievement" + index}>{entry.achievement}</p>);
                  })}
                </>
              );
            })}
          </div>
          <div>
            <h1>{content.teamHeader}</h1>
            <div className="columns is-multiline is-mobile team-members">
              <div className="column is-full">
                {content.currentMembers.map((entry, index) => { return (<p key={index}>{entry.teamMember}</p>); })}
              </div>
              <div className="column is-full previous-members">
                {content.pastMembers.map((entry, index) => { return (<p key={index}>{entry.teamMember}</p>); })}
              </div>
            </div>
          </div>
        </section>
      </div>
    </LayoutArraiolos>
  );
};

export default StudioPage;

export const Head = () => (
  <SEO title={PageTitles.STUDIO} />
);

export const StudioPageQuery = graphql`
 query StudioPage {
    markdownRemark(frontmatter: { templateKey: { eq: "studio-page" } }) {
      frontmatter {
        studioName
        descriptionParagraphs {
          paragraph
        }
        architects {
          name
          achievements {
            achievement
          }
        }
        teamHeader
        currentMembers {
          teamMember
        }
        pastMembers {
          teamMember
        }
      }
    }
}
`;
